<template>
  <div>
    <div class="nvs-create">
      <ul>
        <li v-for="(item, idx) in question.options" :key="idx" @click="submit(item.label, item.rating)">
          <app-form-radio class="single-radio"></app-form-radio>
          <app-heading level="h6" :style="{'color': colors.splashColor}">{{ item.label }}</app-heading>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { translator } from '@/_helpers'

export default {
  name: 'app5radio',
  props: ['question', 'colors'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-radio': () => import('@binds-tech/binds-design-system/src/components/Form/Radio')
  },
  data () {
    return {
    }
  },
  mounted () {
    this.$root.$on('footer-submit', payload => {
      if (payload) {
        this.submitEvents({ skip: true })
      }
    })
  },
  methods: {
    t (text) {
      return translator.translateThisText(this.question.language, text)
    },
    submit (label, value) {
      const body = {}
      body.rating = value
      body.value = label
      this.submitEvents(body)
    },
    submitEvents (data) {
      this.$emit('value-changed', data)
      this.$emit('question-answered', true)
    }
  }
}
</script>

<style lang="scss">
.nvs-create {
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    li {
      display: flex;
      align-items: center;
      height: 25px;
      margin-bottom: 20px;
      cursor: pointer;
      .single-radio {
        width: 10% !important;
        margin: 0 !important;
        div {
          .label {
            .checkmarkv2 {
              background: transparent;
              border: 2px solid var(--splashColor);
            }
          }
        }
      }
      .heading {
        text-align: left;
        margin-left: 15px;
      }
    }
  }
}
.single-radio::after {
  display: none !important;
}

// Responsivity to mobile
@media only screen and (max-width: 850px) {
  .nvs-create {
    height: 100%;
    ul {
      li {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
